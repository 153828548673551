import React from 'react';
import { Link, Paragraph } from '@leafwell/components';
import Image from 'next/image';

import { useWordPressContext } from 'contexts/wordpress-provider';
import { useEventsTracker } from 'contexts/eventsTracker';

export type SocialItems = { socialNetwork: string; url: string }[];

const socialNetworkImageSizes = {
  facebook: {
    width: 20,
    height: 20,
  },
  instagram: {
    width: 20,
    height: 20,
  },
  linkedin: {
    width: 20,
    height: 20,
  },
  twitter: {
    width: 20,
    height: 16,
  },
  youtube: {
    width: 23,
    height: 16,
  },
};

const SocialItemsList: React.FC<{
  items?: SocialItems;
  variant?: 'default' | 'withIcon' | 'labelAndIcon';
  className?: string;
  isLeafwell?: boolean;
}> = ({ items, variant, className, isLeafwell = false }) => {
  const showIcon = variant !== 'default';
  const showLabel = variant !== 'withIcon';
  const { trackEvent } = useEventsTracker();
  return items?.length > 0 ? (
    <ul
      className={[
        'flex flex-wrap items-center',
        variant === 'withIcon' ? 'md:justify-end' : '',
        variant === 'labelAndIcon' ? 'gap-x-8 gap-y-2' : '',
        className,
      ].join(' ')}
    >
      {items.map(({ socialNetwork, url }, key) => {
        const socialNetworkName = socialNetwork.toLowerCase();

        return (
          <li
            key={key}
            className={[
              variant !== 'labelAndIcon' ? 'mr-3 md:ml-5 md:mr-0' : '',
              variant === 'withIcon' ? 'mt-3 md:mt-5 mr-5' : '',
            ].join(' ')}
          >
            <Link
              href={url}
              className={[
                'items-center capitalize leading-normal',
                variant === 'labelAndIcon' ? 'flex gap-x-2' : '',
              ].join(' ')}
              target="_blank"
              rel="noopener noreferrer"
              theme={showIcon ? 'dark' : 'light'}
              onClick={() => {
                if (isLeafwell) {
                  trackEvent({
                    event: 'select_content',
                    content_type: socialNetwork,
                    item_id: 'leafwell_social_network',
                    origin: 'block_social_network',
                  });
                }
              }}
            >
              {showIcon && (
                <Image
                  height={socialNetworkImageSizes[socialNetworkName].height}
                  width={socialNetworkImageSizes[socialNetworkName].width}
                  src={`/social/${socialNetworkName}.svg`}
                  alt={socialNetwork}
                  className={[
                    'block w-auto',
                    variant === 'withIcon'
                      ? 'opacity-20 hover:opacity-100 transition duration-150'
                      : 'hover:opacity-20',
                  ].join(' ')}
                />
              )}
              {showLabel && (
                <Paragraph size={variant === 'default' ? 'small' : 'medium'}>
                  {socialNetwork}
                </Paragraph>
              )}
            </Link>
          </li>
        );
      })}
    </ul>
  ) : null;
};

const CustomSocialItems: React.FC<{
  customItems?: SocialItems;
}> = ({ customItems }) => {
  return <SocialItemsList variant="labelAndIcon" items={customItems} />;
};

const LeafwellSocialItems: React.FC<{
  variant?: 'default' | 'withIcon';
}> = ({ variant = 'default' }) => {
  const {
    social: { items, label, title },
  } = useWordPressContext();
  const showIcon = variant === 'withIcon';

  return items && items.length > 0 ? (
    <>
      {showIcon ? (
        <p className="text-2xl sm:text-3xl leading-tight">{label}</p>
      ) : (
        <Paragraph size="small" className="font-medium">
          {title}
        </Paragraph>
      )}
      <SocialItemsList {...{ variant, items }} isLeafwell={true} />
    </>
  ) : null;
};

const SocialItems: React.FC<{
  variant?: 'default' | 'withIcon';
  customItems?: SocialItems;
}> = ({ variant = 'default', customItems = undefined }) => {
  return customItems?.length > 0 ? (
    <CustomSocialItems {...{ customItems }} />
  ) : (
    <LeafwellSocialItems {...{ variant }} />
  );
};

export default SocialItems;
